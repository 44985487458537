import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import PostItem from "./PostItem"
import RigthBanner from "../templates/rigthBanner"

const PostLoop = ({ posts, sectionTitle }) => {
  const data = useStaticQuery(graphql`
    query PostPerPageQuery {
      site {
        siteMetadata {
          postPerPage
        }
      }
    }
  `)
  const { postPerPage } = data.site.siteMetadata

  const [isLoading, setIsLoading] = useState(false)
  const [visiblePosts, setVisiblePosts] = useState([
    ...posts.slice(0, postPerPage),
  ])
  const [hasMore, setHasMore] = useState(posts.length > postPerPage)

  const handleLoadMore = () => {
    setIsLoading(true)
  }

  useEffect(() => {
    if (isLoading && hasMore) {
      const isMore = posts.length > visiblePosts.length
      const nextPagePosts = isMore
        ? [
            ...posts.slice(
              visiblePosts.length,
              visiblePosts.length + postPerPage
            ),
          ]
        : []
      setVisiblePosts([...visiblePosts, ...nextPagePosts])
      setIsLoading(false)
    }
  }, [isLoading, hasMore, posts, visiblePosts, postPerPage])

  useEffect(() => {
    const isMore = visiblePosts.length < posts.length
    setHasMore(isMore)
  }, [visiblePosts, posts])

  return (
    <div className="container">
      <div className="row">
        <div className="col-lg-10 offset-lg-1 js-post-list-wrap quran-videos-cont">
          {sectionTitle && (
            <h2 className="h4 section-title">
              <span>{sectionTitle}</span>
            </h2>
          )}
          {visiblePosts.map((post, index) => (
            <PostItem post={post} key={index} />
          ))}
        </div>
        <RigthBanner/>
      </div>
      {hasMore && (
        <div className="row">
          <div className="col">
            <div className="pagination-wrap text-center" id="pagination-wrap">
              <button
                className={`btn ${isLoading ? "loading" : ""}`}
                disabled={isLoading}
                onClick={handleLoadMore}
              >
                <span>Show more posts</span>
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default PostLoop 
